<template>
  <div class="landingPage">
    <section class="conOfAllHome fill-height">
      <v-container class="fill-height justify-center d-flex align-center">
        <v-row class="conOfCard justify-center d-flex align-center">
          <div class="conOfquestions">
            <v-card class="mx-auto">
              <v-card-text class="justify-center d-flex align-center">
                <transition name="slideUp">
                  <div class="conOfSections d-flex align-center">
                    <div class="conOfQuestion" v-if="dataSelected === 1">
                      <div class="q1">What are you looking for ?</div>
                      <v-radio-group v-model="filter.organization" row>
                        <v-radio
                          label="PreSchool"
                          value="PreSchool"
                          class="red-radio"
                          @change="dataSelected = 2"
                        ></v-radio>
                        <v-radio
                          label="School"
                          value="School"
                          class="red-radio"
                          @change="dataSelected = 2"
                        ></v-radio>
                        <v-radio
                          label="Univeristy"
                          value="Univeristy"
                          class="red-radio"
                          @change="dataSelected = 2"
                        ></v-radio>
                      </v-radio-group>
                    </div>

                    <div class="conOfQuestion" v-if="dataSelected === 2">
                      <div class="q1">Whats’ your location?</div>
                      <v-select
                        :items="items"
                        label="Select Location"
                        v-model="filter.location"
                        solo
                        hide-details
                        :menu-props="{ bottom: true, offsetY: true }"
                        attach
                        @change="dataSelected = 3"
                      ></v-select>
                    </div>
                    <div class="conOfQuestion" v-if="dataSelected === 3">
                      <div class="q1">What’s your budget?</div>
                      <v-subheader>{{ filter.budget }}</v-subheader>
                      <v-slider
                        min="10000"
                        max="50000"
                        v-model="filter.budget"
                        color="#ED1C24"
                        step="5000"
                        @click="dialog = true"
                      ></v-slider>
                    </div>
                    <div>
                      <v-btn text color="#98140E"> Skip </v-btn>
                    </div>
                  </div>
                </transition>
                <v-card-actions> </v-card-actions>
              </v-card-text>
            </v-card>
            <div class="cloud01"></div>
            <div class="cloud02"></div>
          </div>
        </v-row>

        <v-row justify="center">
          <v-dialog v-model="dialog" max-width="500">
            <v-card class="tour-card text-center pa-4">
              <div class="d-flex justify-center align center">
                <img src="@/assets/img/girl.png" />
              </div>
              <v-card-title class="tour-txt d-flex justify-center align center">
                Glad To Help You !
              </v-card-title>

              <v-card-text class="tour-subtxt">
                Based on your answers we collect group of organizations match
                your interest
              </v-card-text>

              <v-card-actions class="d-flex justify-center align center">
                <v-btn
                  class="btn-list"
                  text
                  @click="$router.push({ name: 'schools', query: filter })"
                >
                  View List
                </v-btn>

                <a class="btn-tour" href="http://hall.cat-sw.com/">
                  Continue Tour
                </a>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </section>
  </div>
</template>
<script>
export default {
  data: () => ({
    dataSelected: 1,
    items: ["New Cairo", "Maadi", "6 October"],
    filter: {
      location: "",
      budget: "100",
      organization: "",
    },

    dialog: false,
  }),
};
</script>

<style lang="scss" scoped>
@import "./_landing-page.scss";
</style>
